import { ReactNode, createContext, useContext, useState } from 'react';
import APIClient from '../backend/api';

const APIContext = createContext(new APIClient(process.env.REACT_APP_API_URL || ''));

function APIProvider({ children }: { children: ReactNode }) {
  const [apiClient] = useState<APIClient>(new APIClient(process.env.REACT_APP_API_URL || ''));
  return <APIContext.Provider value={apiClient}>{children}</APIContext.Provider>;
}

function useApi() {
  return useContext(APIContext);
}

export { useApi, APIProvider };