import './index.css';
export default function Profile() {
  return (
    <div className="container-lg vh-100 d-flex flex-column">
      <ProfileTop />
      <ProfileDetails />
    </div>
  )
}

function ProfileTop() {
  return <div className="bg-white vh-30">
    test
  </div>;
}

function ProfileDetails() {
  return <div className='bg-white mt-1 vh-70'>details</div>
}