export class User {
  id: number = 0;
  email: string = "";
  emailVerified: boolean = false;
}

export class ResponseDTO<T> {
  success: boolean;
  error?: string;
  data?: T

  constructor(success: boolean, data?: T, error?: string) {
    this.success = success;
    this.data = data;
    this.error = error;
  }
}