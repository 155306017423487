import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import { NavLink } from 'react-router-dom';
import { useI18n } from '../i18n';

export type NavBarItem = {
  nameI18nKey: string,
  to: string,
  key: string,
}

export default function TopNavBar(props: {
  pageTitleI18nKey: string,
  navItems: NavBarItem[],
  className?: string,
}) {
  const i18n = useI18n();
  return <nav className={`navbar navbar-expand-md navbar-light sticky-top ${props.className ?? ''}`} role="navigation">
    <div className="container-fluid">
      <h2>{i18n.t(props.pageTitleI18nKey)}</h2>
      <Nav className="ml-auto">
        {props.navItems.map(a => {
          return <NavItem key={a.key}>
            <NavLink to={a.to} className="nav-link" replace>
              {i18n.t(a.nameI18nKey)}
            </NavLink>
          </NavItem>
        })}
      </Nav>
    </div>
  </nav>
}