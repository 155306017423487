import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { User } from '../backend/models';
import { useApi } from './api';

interface AuthContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    api.getUser().then(resp => {
      if (resp.success && resp.data) {
        auth.setUser(resp.data!);
        return;
      }
      throw new Error('get user info failed')
    }).catch(() => {
      api.logout();
      navigate('/login');
    });
    // only load users on mount once, so disabled exhaustive-deps checking
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api.authToken]);

  if (!api.authToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" replace />;
  }

  return children;
}