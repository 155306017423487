import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import { NavLink } from 'react-router-dom';
import { useI18n } from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import './bottom-tab-bar.css';

export type BottomTabItem = {
  to: string,
  nameI18nKey: string,
  icon: IconDefinition,
  key: string,
  active: boolean,
}
export default function BottomTabBar(props: {
  items: BottomTabItem[],
  className?: string,
}) {
  const tabs = props.items;
  const i18n = useI18n();
  const activeKey = props.items.filter(a => a.active)[0].key;
  return <nav className={`navbar fixed-bottom navbar-light ${props.className || ''}`} role="navigation">
    <Nav className="w-100" activeKey={activeKey}>
      <div className="d-flex flex-row justify-content-around w-100">
        {
          tabs.map(tab => (
            <NavItem key={tab.key} >
              <NavLink to={tab.to} className="nav-link d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <FontAwesomeIcon size="lg" icon={tab.icon} />
                  <div>{i18n.t(tab.nameI18nKey)}</div>
                </div>
              </NavLink>
            </NavItem>
          ))
        }
      </div>
    </Nav>
  </nav>
}