import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

import ImageSrc from '../../assets/images';
import './index.css';
import { useI18n } from '../../common/i18n';
import { useCallback, useEffect, useState } from 'react';
import { validateEmail, validatePassword } from './validators';
import { useApi } from '../../common/api';
import { useAuth } from '../../common/auth';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const type = queryParams.get("type");

  const showResetPasswordForm = type === 'reset-password' && token;
  const showVerifyEmailForm = type === 'verify-email' && token;

  const getForm = () => {
    if (showResetPasswordForm) {
      return <ResetPasswordForm token={token} />;
    }
    if (showVerifyEmailForm) {
      return <VerifyEmail token={token} />;
    }
    return <LoginForm />;
  }

  return (
    <div className="bg-image" style={{
      backgroundImage: `url('${ImageSrc.greyWall}')`, height: '100vh', display: 'flex', justifyContent: 'center',
      alignItems: 'center'
    }}>
      {getForm()}
    </div >
  );
}

function ResetPasswordForm(props: { token: string }) {
  const api = useApi();
  const i18n = useI18n();

  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [submitFailed, setSubmitFailed] = useState(false);


  const passwordInputHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const passwordWeak = i18n.t('password_invalid');
    const password = e.target.value;
    setSubmitFailed(false);
    setPassword(password);
    setPasswordMatch(password === confirmedPassword);
    if (!validatePassword(password)) {
      setPasswordError(passwordWeak);
    } else {
      setPasswordError("")
    }
  }, [confirmedPassword, i18n]);

  const confirmPasswordInputHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordMatch(e.target.value === password);
    setConfirmedPassword(e.target.value);
  }, [password]);

  const onSubmit = useCallback(() => {
    const passwordWeak = i18n.t('password_invalid');
    if (!validatePassword(password)) {
      setPasswordError(passwordWeak);
      return;
    }

    if (password !== confirmedPassword) {
      setPasswordMatch(false);
      return;
    }

    setLoading(true);

    api.resetPassword(props.token, password).then(resp => {
      setLoading(false);
      if (!resp.success) {
        throw new Error('reset failed');
      }
      window.location.href = '/login';
    }).catch(() => {
      setSubmitFailed(true);
      setLoading(false);
    })
  }, [api, confirmedPassword, i18n, password, props.token]);

  const submitDisabled = !password || !passwordMatch;

  return < Form className='central-form' >
    <h3 className='mb-4'>{i18n.t('reset_password_title')}</h3>

    <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>{i18n.t('new_password')}</Form.Label>
      <Form.Control type="password" className={submitFailed ? 'border-danger' : ''} placeholder={i18n.t('password')} onChange={passwordInputHandler} />
      {passwordError ? <Form.Text className="text-danger">
        {passwordError}
      </Form.Text> : null}
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
      <Form.Label>{i18n.t('confirm_password')}</Form.Label>
      <Form.Control type="password" className={submitFailed || !passwordMatch ? 'border-danger' : ''} placeholder={i18n.t('repeat_password')} onChange={confirmPasswordInputHandler} />
    </Form.Group>

    <Button variant="primary" className='w-100' disabled={submitDisabled} onClick={onSubmit}>
      {loading ? <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      /> : i18n.t('btn_submit')}
    </Button>
  </Form >
}

function LoginForm() {
  const api = useApi();
  const auth = useAuth();
  const i18n = useI18n();
  const navigate = useNavigate();
  const [loginMode, setLoginMode] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendingForgetPasswordEmail, setSendingForgetPasswordEmail] = useState(false);
  const [infoForgetPasswordEmailSent, setInfoForgetPasswordEmailSent] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const emailInputHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitFailed(false);
    setEmail(e.target.value);
    if (e.target.value && !validateEmail(e.target.value, true)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, []);

  const passwordInputHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const passwordWeak = i18n.t('password_invalid');
    const password = e.target.value;
    setSubmitFailed(false);
    setPassword(password);
    setPasswordError("")

    if (loginMode) {
      // in login, we dont need to validate password
      return;
    }

    setPasswordMatch(password === confirmedPassword);
    if (!validatePassword(password)) {
      setPasswordError(passwordWeak);
    } else {
      setPasswordError("")
    }
  }, [confirmedPassword, i18n, loginMode]);

  const confirmPasswordInputHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordMatch(e.target.value === password);
    setConfirmedPassword(e.target.value);
  }, [password]);

  const handleForgetPassword = useCallback(() => {
    if (!email || emailError) {
      setEmailError(true)
      return;
    }
    setSendingForgetPasswordEmail(true);
    api.sendForgetPasswordEmail(email).then(resp => {
      if (!resp.success) {
        throw new Error('failed')
      }
      setInfoForgetPasswordEmailSent(true);
      setTimeout(() => {
        setInfoForgetPasswordEmailSent(false);
      }, 8000);
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      setSendingForgetPasswordEmail(false);
    })
  }, [api, email, emailError]);

  const onSubmit = useCallback(() => {
    if (!validateEmail(email, false)) {
      setEmailError(true);
      return;
    }

    const passwordWeak = i18n.t('password_invalid');
    if (!validatePassword(password)) {
      setPasswordError(passwordWeak);
      return;
    }

    if (!loginMode && password !== confirmedPassword) {
      setPasswordMatch(false);
      return;
    }

    setLoading(true);

    (loginMode ? api.login.bind(api) : api.signup.bind(api))(email, password).then((resp) => {
      if (resp.success && resp.data?.user) {
        const user = resp.data!.user;
        auth.setUser(user);
        api.setAuthToken(resp.data.token, rememberMe);
        setTimeout(() => {
          setLoading(false);
          navigate('/walls');
        }, 200);
        return;
      }
      throw new Error('login failed');
    }).catch(e => {
      setSubmitFailed(true);
      setLoading(false);
    })
    return;
  }, [api, auth, confirmedPassword, email, i18n, loginMode, navigate, password, rememberMe]);

  const sigupLoginBtnHandler = () => {
    setLoginMode(!loginMode)
    setSubmitFailed(false)
    setEmailError(false)
    setPasswordError("")
  }

  const submitDisabled = (!!emailError || !email) // email is invalid or email is empty
    || (!!passwordError || !password) // password is invalid or password is empty
    || loading // is submitting
    || (!loginMode && !termsAgreed) // in signup mode and terms not checked
    || (!loginMode && !passwordMatch); // in signup mode and confirmed password not match

  return <Form className='central-form'>
    <h3 className='mb-4'>{loginMode ? i18n.t('login_title') : i18n.t('signup_title')}</h3>
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>{i18n.t('email_address')}</Form.Label>
      <Form.Control type="email" className={submitFailed || emailError ? 'border-danger' : ''} placeholder={i18n.t('enter_email')} onChange={emailInputHandler} />
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>{i18n.t('password')}</Form.Label>
      <Form.Control type="password" className={submitFailed ? 'border-danger' : ''} placeholder={i18n.t('password')} onChange={passwordInputHandler} />
      {passwordError ? <Form.Text className="text-danger">
        {passwordError}
      </Form.Text> : null}
    </Form.Group>

    {!loginMode ? <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
      <Form.Label>{i18n.t('confirm_password')}</Form.Label>
      <Form.Control type="password" className={!passwordMatch ? 'border-danger' : ''} placeholder={i18n.t('repeat_password')} onChange={confirmPasswordInputHandler} />
    </Form.Group> : null}

    {loginMode ? <Form.Group className="mb-3 ps-3 pe-3 row justify-content-between ">
      <Form.Check type="checkbox" className="col" onChange={(e) => {
        setRememberMe(e.target.checked);
      }} label={i18n.t('checkbox_remember_me')} />
      <div className="col text-end">
        {sendingForgetPasswordEmail ? <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : <button type="button" className='link-button' onClick={handleForgetPassword}>{i18n.t('forget_password?')}</button>}
      </div>
      <Alert className='mt-3' show={infoForgetPasswordEmailSent} transition={true} variant='success'>{i18n.t('reset_password_email_sent')}</Alert>
    </Form.Group> : <Form.Group className="mb-3 ps-3 pe-3 row justify-content-start  align-items-center">
      <div className="col-auto p-0 agree-to-terms">
        <Form.Check type="checkbox" onChange={(e) => {
          setTermsAgreed(e.target.checked)
        }} label={i18n.t('agree_to_terms')} />
      </div>
      <div className="col ps-1 terms-and-cons">
        <a href={i18n.t('terms_and_conditions_url')} rel="noreferrer" target="_blank">{i18n.t('terms_and_conditions')}</a>
      </div>
    </Form.Group>}

    <Button variant="primary" className='w-100' disabled={submitDisabled} onClick={onSubmit}>
      {loading ? <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      /> : i18n.t('btn_submit')}
    </Button>

    <p className="col w-100 text-center mt-3">
      {loginMode ? i18n.t('no_account?') : i18n.t('already_has_account?')}
      <button type="button" className='link-button' onClick={sigupLoginBtnHandler}>{loginMode ? i18n.t('sign_up') : i18n.t('login')}</button>
    </p>
  </Form >
}

function VerifyEmail(props: { token: string }) {
  const api = useApi();
  const i18n = useI18n();
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [showSent, setShowSent] = useState(false);

  useEffect(() => {
    api.verifyEmail(props.token).then((resp) => {
      if (!resp.success) {
        throw new Error();
      }
      setShowSuccess(true);
      setTimeout(() => {
        navigate('/walls');
      }, 3000);
    }).catch(() => {
      setShowFailure(true);
    });
  }, [api, props.token, navigate]);

  return <div className='central-form'>
    <h3 className='mb-4'>{i18n.t('title_email_verification')}</h3>
    {!showSuccess && !showFailure && !showSent ? <div className='p-2 d-flex justify-centent-center'><Spinner animation="grow" size="sm" variant='success' className="m-auto" /></div> : null}
    <Alert className='mt-3' show={showSuccess} transition={true} variant='success'>{i18n.t('verify_email_success_message')}</Alert>
    <Alert className='mt-3' show={showFailure} transition={true} variant='danger'>{i18n.t('failed_to_verify_email')}</Alert>
    <Alert className='mt-3' show={showSent} transition={true} variant='success'>{i18n.t('verification_email_sent')}</Alert>
    {showFailure ? <Button variant="primary" className='w-100 mt-3' onClick={() => {
      setShowFailure(false);
      api.resentVerificationEmail(props.token).then((resp) => {
        if (resp && resp.success) {
          setShowSent(true);
        } else {
          throw new Error();
        }
      }).catch((err) => {
        window.location.href = '/login';
      });
    }}>
      {i18n.t('resend_verification_email')}
    </Button> : null}
  </div>;
}