import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

export class I18n {
  constructor(private data: Record<string, string>) { }
  public t(key: string): string {
    if (this.data[key]) {
      return this.data[key];
    }
    if (process.env.REACT_APP_LABEL_MISSING_I18N) {
      return `[missing-i18n]: ${key}`;
    }
    return '';
  }

  hasDiff(data: Record<string, string>): boolean {
    for (let k in data) {
      if (data[k] !== this.data[k]) {
        return true;
      }
    }
    return false;
  }
}

async function loadI18nAsset(): Promise<Record<string, string> | null> {
  const fullLang = navigator.language || navigator.languages[0] || 'en';
  const lang = fullLang.split('-')[0];
  const resp = await fetch(`/i18n/${lang}.json`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  }).catch(err => {
    return null;
  });

  if (!resp) {
    return null;
  }

  if (resp.status !== 200 && resp.status !== 304) {
    return null;
  }

  const data = await resp.json().catch(err => {
    return null;
  });
  return data;
}

async function loadAndSetI18nAsset(i18n: I18n, setI18n: React.Dispatch<React.SetStateAction<I18n>>) {
  const asset = await loadI18nAsset();
  if (asset && i18n.hasDiff(asset)) {
    setI18n(new I18n(asset));
  }
}

const MyI18nContext = createContext<I18n>(null!);

function I18nProvider({ children }: { children: ReactNode }) {
  const [i18n, setI18n] = useState<I18n>(new I18n({}));

  useEffect(() => {
    loadAndSetI18nAsset(i18n, setI18n)
  }, [i18n]);

  return <MyI18nContext.Provider value={i18n}>{children}</MyI18nContext.Provider>;
}

function useI18n() {
  return useContext(MyI18nContext);
}

export { useI18n, I18nProvider };
export default MyI18nContext;