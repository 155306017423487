import { getJwtPayload, hashPassword } from "./hasher";
import { ResponseDTO, User } from "./models";

export const AUTH_TOKEN_KEY = 'auth_token';

export default class APIClient {
  private _authToken?: string;

  public get authToken(): string | undefined {
    if (this._authToken) {
      return this._authToken
    }

    const stored = localStorage[AUTH_TOKEN_KEY];
    if (stored) {
      this._authToken = stored;
    }
    return stored;
  }

  public setAuthToken(val: string | undefined, persist: boolean) {
    if (val) {
      this._authToken = val;
      if (persist) {
        localStorage[AUTH_TOKEN_KEY] = val;
      }
    } else {
      this._authToken = undefined;
      delete localStorage[AUTH_TOKEN_KEY];
    }
  }

  constructor(private baseURL: string) { }

  private getHeaders(providedToken?: string): HeadersInit {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };

    if (providedToken) {
      headers['authorization'] = providedToken;
    } else if (this.authToken) {
      headers['authorization'] = this.authToken;
    }

    return headers;
  }

  private async hashPassword(email: string, password: string): Promise<string> {
    const saltResp = await this.getSalt(email);
    if (!saltResp.success) {
      throw new Error('fail to communicate with server')
    }

    const passwordHash = await hashPassword(password, saltResp.data!);
    return passwordHash;
  }

  public async login(email: string, password: string): Promise<ResponseDTO<{ user: User, token: string }>> {
    const passwordHash = await this.hashPassword(email, password);
    const resp = await fetch(`${this.baseURL}/api/auth/login`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        passwordHash
      }),
      headers: this.getHeaders()
    });

    if (resp.status !== 200) {
      return new ResponseDTO<{ user: User, token: string }>(false, undefined, 'request_failed');
    }

    const data = await resp.json() as ResponseDTO<{ user: User, token: string }>;

    return data;
  }

  public async signup(email: string, password: string): Promise<ResponseDTO<{ user: User, token: string }>> {
    const passwordHash = await this.hashPassword(email, password);
    const resp = await fetch(`${this.baseURL}/api/auth/signup`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        passwordHash
      }),
      headers: this.getHeaders()
    });

    if (resp.status !== 200) {
      return new ResponseDTO<{ user: User, token: string }>(false, undefined, 'request_failed');
    }

    const data = await resp.json() as ResponseDTO<{ user: User, token: string }>;

    return data;
  }


  public async resetPassword(token: string, password: string): Promise<ResponseDTO<void>> {
    const payload = getJwtPayload(token);
    if (!payload || !payload.email) {
      return new ResponseDTO<void>(false, undefined, 'invalid_token');
    }

    const passwordHash = await this.hashPassword(payload!.email, password);
    const resp = await fetch(`${this.baseURL}/api/auth/reset-password`, {
      method: 'POST',
      body: JSON.stringify({
        token,
        passwordHash,
      }),
      headers: this.getHeaders()
    });

    if (resp.status !== 200) {
      return new ResponseDTO<void>(false, undefined, 'request_failed');
    }

    const data = await resp.json() as ResponseDTO<void>;

    return data;
  }

  public async getSalt(email: string): Promise<ResponseDTO<string>> {
    const resp = await fetch(`${this.baseURL}/api/auth/salt`, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      headers: this.getHeaders()
    });

    if (resp.status !== 200) {
      return new ResponseDTO<string>(false, undefined, 'request_failed');
    }

    const data = await resp.json() as ResponseDTO<string>;

    return data;
  }

  public async getUser(): Promise<ResponseDTO<User>> {
    const resp = await fetch(`${this.baseURL}/api/user`, {
      method: 'GET',
      headers: this.getHeaders()
    });

    if (resp.status !== 200) {
      return new ResponseDTO<User>(false, undefined, 'request_failed');
    }

    const data = await resp.json() as ResponseDTO<User>;

    return data;
  }

  public async sendForgetPasswordEmail(email: string): Promise<ResponseDTO<void>> {
    const resp = await fetch(`${this.baseURL}/api/auth/forget-password`, {
      method: 'POST',
      body: JSON.stringify({
        email
      }),
      headers: this.getHeaders()
    });

    if (resp.status !== 200) {
      return new ResponseDTO<void>(false, undefined, 'request_failed');
    }

    const data = await resp.json() as ResponseDTO<void>;

    return data;
  }

  public async verifyEmail(token: string): Promise<ResponseDTO<void>> {
    const resp = await fetch(`${this.baseURL}/api/auth/forget-password`, {
      method: 'POST',
      body: JSON.stringify({
        token
      }),
      headers: this.getHeaders()
    });

    if (resp.status !== 200) {
      return new ResponseDTO<void>(false, undefined, 'request_failed');
    }

    const data = await resp.json() as ResponseDTO<void>;

    return data;
  }

  public async resentVerificationEmail(token: string): Promise<ResponseDTO<void>> {
    const resp = await fetch(`${this.baseURL}/api/auth/resend-verification-email`, {
      method: 'POST',
      body: JSON.stringify({
        token
      }),
      headers: this.getHeaders()
    });

    if (resp.status !== 200) {
      return new ResponseDTO<void>(false, undefined, 'request_failed');
    }

    const data = await resp.json() as ResponseDTO<void>;

    return data;
  }

  public async uploadFile(file: File, orderId: number, onProgressUpdate: (p: number) => void): Promise<ResponseDTO<void>> {
    return new Promise((resolve) => {
      let data = new FormData();
      const orignalFilename = file.name;
      const split = orignalFilename.split('.');
      const fileName = split.slice(0, -1).join('.');
      const extension = split[split.length - 1];
      const updatedFilename = `${fileName}-${orderId}-${Date.now()}.${extension}`;
      data.append('file', file, updatedFilename);

      let request = new XMLHttpRequest();
      request.open('POST', `${this.baseURL}/api/file/upload/${orderId}`);
      request.setRequestHeader('Authorization', this.authToken ?? '');

      // upload progress event
      request.upload.addEventListener('progress', function (e) {
        // upload progress as percentage
        let percent_completed = (e.loaded / e.total) * 100;
        onProgressUpdate(percent_completed);
      });

      // request finished event
      request.addEventListener('load', function (e) {
        if (request.status === 200) {
          resolve(new ResponseDTO<void>(true, request.response.order));
          return;
        }

        resolve(new ResponseDTO<void>(false, undefined, 'request_failed'));
      });

      request.addEventListener('error', function () {
        resolve(new ResponseDTO<void>(false, undefined, 'request_failed'));
      });

      // send POST request to server
      request.send(data);
    });
  }

  public async logout() {
    this.setAuthToken(undefined, true);
  }
}