
import '../assets/custom.scss';

import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import { useI18n } from '../common/i18n';
import { AuthProvider, RequireAuth } from '../common/auth';
import Login from './login';
import Home from './home';
import { useEffect } from "react";

import './App.css';
import { APIProvider } from "../common/api";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/walls" replace />,
  },
  {
    path: "/walls",
    element: <RequireAuth>
      <Home selectedTab='tab_walls' />
    </RequireAuth>,
  },
  {
    path: "/photos",
    element: <RequireAuth>
      <Home selectedTab='tab_photos' />
    </RequireAuth>,
  },
  {
    path: "/profile",
    element: <RequireAuth>
      <Home selectedTab='tab_profile' />
    </RequireAuth>,
  },
  {
    path: "/walls/s/:token",
    element: null,
  },
  {
    path: "/walls/:id",
    element: <RequireAuth>
      <div></div>
    </RequireAuth>,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

export default function RootApp() {
  const i18n = useI18n();
  useEffect(() => {
    document.title = i18n.t('app_title');
  }, [i18n]);
  return <APIProvider><AuthProvider><RouterProvider router={router} /></AuthProvider></APIProvider>;
}
