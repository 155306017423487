import TopNavBar, { NavBarItem } from '../../common/components/top-nav-bar';
import BottomTabBar, { BottomTabItem } from '../../common/components/bottom-tab-bar';
import { faHome, faPerson, faPhotoVideo } from '@fortawesome/free-solid-svg-icons';

import ImageSrc from '../../assets/images';
import Walls from '../walls';
import Photos from '../photos';
import Profile from '../profile';

import './index.css';

export type ComponentWithKey = {
  key: string,
  component: JSX.Element,
}

const HomeTabs: (NavBarItem & BottomTabItem & ComponentWithKey)[] = [
  { nameI18nKey: 'tab_title_photos', to: '/photos', icon: faPhotoVideo, key: 'tab_photos', active: false, component: <Photos /> },
  { nameI18nKey: 'tab_title_walls', to: '/walls', icon: faHome, key: 'tab_walls', active: false, component: <Walls /> },
  { nameI18nKey: 'tab_title_profile', to: '/profile', icon: faPerson, key: 'tab_profile', active: false, component: <Profile /> }
];

function Home(props: {
  selectedTab: string;
}) {
  const selectedTab = HomeTabs.filter(a => a.key === props.selectedTab)[0];
  return (
    <>
      <div className="bg-image" style={{
        backgroundImage: `url('${ImageSrc.brickWall}')`, height: '100vh', minHeight: '100vh', backgroundRepeat: 'no-repeat', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundSize: 'cover',
        overflow: 'hidden', backgroundAttachment: 'fixed',
      }}>
      </div>

      <div className="container-lg" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <TopNavBar className='d-none d-lg-block pt-2' pageTitleI18nKey={selectedTab.nameI18nKey} navItems={HomeTabs.filter(tab => tab.key !== selectedTab.key)} />
        {selectedTab.component}
        <BottomTabBar className='d-block d-lg-none' items={HomeTabs.map(a => {
          return { ...a, active: a.key === selectedTab.key }
        })} />
      </div >
    </>
  );
}

export default Home;