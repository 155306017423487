const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{1,})+$/;
const emailFirstHalf = /^[\w.-]+$/
const emailBeforeDot = /^(?!.*@.*@)[\w.-]+@[\w.-]+$/
export function validateEmail(email: string, silenceIfPossibleToBeValid: boolean): boolean {
  if (!silenceIfPossibleToBeValid) {
    return emailRegex.test(email);
  }

  if (email.endsWith('@') || email.endsWith('.')) {
    return validateEmail(email.substring(0, email.length - 1), true);
  }

  if (!email.includes('@')) {
    return emailFirstHalf.test(email);
  }

  if (!(email.split('@')[1]).includes('.')) {
    return emailBeforeDot.test(email)
  }

  return emailRegex.test(email);
}

// const strongPasswordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
const mediumPasswordReg = /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d@$!%*#?&-]{8,}$/
export function validatePassword(password: string): boolean {
  return mediumPasswordReg.test(password)
}